import { setPublicPath } from 'systemjs-webpack-interop';

/* Make sure your import map has the name of your module in it. Example:
{
  "imports": {
    "@org-name/my-module": "https://example.com/dist/js/main.js"
  }
}
 */

// __webpack_public_path__ will be set to https://example.com/dist/js/
setPublicPath('@imunify/nav-root');


import { enableProdMode, NgZone } from '@angular/core';

import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { singleSpaAngular } from 'single-spa-angular';

import { environment } from './environments/environment';
import { loadTestInterceptor } from './app/core/misc';
import { NavRootModule } from './app/core/nav-root.module';
import { wrapLifecycleMethod } from 'common.single-spa';

if (environment.production) {
    try {
        // The `enableProdMode` will throw an error if it's called multiple times,
        // but it may be called multiple times when dependencies are shared.
        enableProdMode();
    } catch {
        // Nothing to do here.
    }
}

if (TEST) {
    loadTestInterceptor();
}

// Export a service class (injection token) and the shared injector, so that others can get the same
// instance from the injector.
import { AppState } from 'app/core/app.service';
import { AuthState } from 'app/services/auth-state';
import { LicenseState } from 'app/services/license-state';
import { RequestsState } from 'app/services/requests-state';
import { MyImunifyState } from 'app/services/my-imunify-state';
import { getSharedInjector } from 'app/core/shared';

export { AppState, AuthState, LicenseState, RequestsState, MyImunifyState, getSharedInjector };

const lifecycles = singleSpaAngular({
    bootstrapFunction: () => platformBrowserDynamic().bootstrapModule(NavRootModule),
    template: '<nav-root />',
    // Do not provide these. If provided, Single-Spa tries to "fix" angular routing to let multiple
    // apps coexist. However, their "fix" brings more issues than benefits.
    // For the same reason, we don't need `getSingleSpaExtraProviders` above.
    // Router,
    // NavigationStart,
    NgZone,
});

export const bootstrap = wrapLifecycleMethod(lifecycles.bootstrap, '@imunify/nav-root');
export const mount = wrapLifecycleMethod(lifecycles.mount, '@imunify/nav-root');
export const unmount = wrapLifecycleMethod(lifecycles.unmount, '@imunify/nav-root');
