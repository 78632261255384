import {
    BoolFeature, FeatureName, ReportableFeature,
} from '../features-management';
import { DEFAULT_DATA } from './misc';

export const responses = {
    featuresManagementAllowed: {
        data: {
            ...DEFAULT_DATA,
            items: [
                FeatureName.av,
                FeatureName.proactive,
            ],
        },
    },
    featuresManagementNative: {
        data: {
            ...DEFAULT_DATA,
            items: {
                supported: true,
                enabled: false,
            },
        },
    },
    featuresManagementGet: {
        data: {
            ...DEFAULT_DATA,
            items: {
                proactive: BoolFeature.full,
                av: ReportableFeature.full,
            },
        },
    },
    featuresManagementDefaults: {
        data: {
            ...DEFAULT_DATA,
            items: {
                proactive: true,
                av: true,
                webshield: false,
                captcha: true,
            },
        },
    },
    featuresManagementUsers: {
        data: {
            ...DEFAULT_DATA,
            items: [
                {
                    name: 'user1',
                    domains: [
                        'just.domains.list',
                        'just.domains1.list',
                        'just.domains2.list',
                        'just.domains3.list',
                        'just.domains4.list',
                        'just.domains5.list',
                        'just.domains6.list',
                        'just.domains7.list',
                        'just.domains8.list',
                    ],
                    features: {
                        proactive: true,
                        av: true,
                        webshield: false,
                        captcha: false,
                    },
                },
                {
                    name: 'user2',
                    domains: [
                        'just.domains9.list',
                        'just.domains10.list',
                        'just.domains11.list',
                        'just.domains12.list',
                        'just.domains13.list',
                        'just.domains14.list',
                        'just.domains15.list',
                        'just.domains16.list',
                    ],
                    features: {
                        proactive: false,
                        av: false,
                        webshield: true,
                        captcha: false,
                    },
                },
                {
                    name: 'user3',
                    domains: [
                        'just.domains17.list',
                        'just.domains18.list',
                        'just.domains19.list',
                        'just.domains20.list',
                        'just.domains21.list',
                        'just.domains22.list',
                        'just.domains23.list',
                        'just.domains24.list',
                    ],
                    features: {
                        proactive: false,
                        av: false,
                        webshield: false,
                        captcha: true,
                    },
                },
                {
                    name: 'user4',
                    domains: [
                        'just.domains25.list',
                    ],
                    features: {
                        proactive: true,
                        av: false,
                        webshield: false,
                        captcha: true,
                    },
                },
            ],
            max_count: 100,
        },
    },
};
