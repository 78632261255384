import { Observable, timer } from 'rxjs';
import { mapTo } from 'rxjs/operators';
import { RequestParams, WindowInterface } from '@imunify360-api/misc';
import { testMode } from 'app/services/misc';
import { defaultMockList, Mock } from 'app/services/mock';

declare var window: WindowInterface;

export class MockProvider {

    static getMock<R, T>(requestParams: RequestParams<R>,
                  sourceParams: T,
                  className: string): Observable<R> | null {
        // DO NOT REPLACE THIS WITH if(!TEST) { return null; }
        // positive condition is needed for tree-shaking of `Mock`
        if (TEST && testMode(className) && requestParams.method !== void 0) {
            const method = requestParams.method.join(' ');
            const hasMock: boolean = !!window.i360Test.mock[method];

            const mock = hasMock ? window.i360Test.mock[method]
                : MockProvider.getDefaultMock(requestParams, sourceParams);

            const result = {
                ...{result: 'success', messages: []},
                ...mock,
                ...{
                    data: {...Mock.defaultData, ...mock.data},
                },
            };

            console.log(`XHR request:`, requestParams);
            console.log(`XHR ${hasMock ? '' : 'default '}mock: %c${method}`, `color:green`, result);

            // simulating asynchronous run
            return timer(0).pipe(
                mapTo(result),
            );
        }

        return null;
    }

    static getDefaultMock<R, T>(
        requestParams: RequestParams<R>,
        sourceParams: T,
    ): { data: any } | null {
        if (!TEST) {
            return null;
        }

        for (let item of defaultMockList()) {
            try {
                const source = item.api(sourceParams);
                const notVoid = source.method !== void 0 && requestParams.method !== void 0;
                const equal = source.method.join(' ') === requestParams.method.join(' ');
                if (notVoid && equal) {
                    return item.response;
                }
            } catch {
            }
        }

        return {data: {}};
    }
}
