// Here we can keep self-contained shared services with their own Injector.
// NavRoot SPA must import it locally as `from 'app/core/shared'`, while every other SPA must
// import it from `@imunify/nav-root`.

import { Injector } from '@angular/core';
import { AppState } from 'app/core/app.service';
import { AuthState } from 'app/services/auth-state';
import { LicenseState } from 'app/services/license-state';
import { RequestsState } from 'app/services/requests-state';
import { MyImunifyState } from 'app/services/my-imunify-state';

// Injector with services that we want to share between SPAs.
// It contains instances of classes from NavRoot SPA.
let sharedInjector: Injector;

// Exported, so we can reset (re-create) these services in tests without page reload.
export const resetSharedInjector = () => {
    sharedInjector = Injector.create({
        providers: [
            {provide: AppState, useClass: AppState, deps: []},
            {provide: AuthState, useClass: AuthState, deps: []},
            {provide: LicenseState, useClass: LicenseState, deps: [AuthState]},
            {provide: RequestsState, useClass: RequestsState, deps: [AppState, AuthState, LicenseState]},
            {provide: MyImunifyState, useClass: MyImunifyState, deps: []},
        ]
    });
};
resetSharedInjector();

export const getSharedInjector = () => sharedInjector;
