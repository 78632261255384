// es6 import not working with es2016 target (in unit tests)
import { of, EMPTY, Observable } from 'rxjs';
import jwtDecode from 'jwt-decode';
import {
    ActivatedRouteSnapshot, CanActivate, CanLoad, PRIMARY_OUTLET,
    Route,
    Router,
    RouterStateSnapshot, UrlSegment,
    UrlTree
} from '@angular/router';
import { Location } from '@angular/common';
import { Panel } from 'app/utils/panel';
import { switchMap } from 'rxjs/operators';
import {
    HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse,
} from '@angular/common/http';
import { AgentResponse } from '@imunify360-api/misc';
import { Injectable, Injector } from '@angular/core';
import { postOnly } from 'app/interceptors/utils';
import { INVALID_TOKEN_MESSAGE } from '@imunify360-api/auth';
import { AuthState } from './auth-state';

export const TOKEN_FIELD_NAME = 'jwt';
export const TOKEN_LOCAL_STORAGE_FIELD_NAME = 'I360_AUTH_TOKEN';
declare const i360role: any;
declare const i360userName: any;

export enum I360Role {
    admin = 'admin',
    client = 'client',
    none = 'none',
}

type TokenData = {
    user_type: I360Role,
    username: string,
};

@Injectable()
export class AuthService implements HttpInterceptor, CanActivate, CanLoad {
    constructor(
        private location: Location,
        private injector: Injector,
        private authState: AuthState,
    ) {
        this.discoverToken();
    }

    logout() {
        this.setToken('');
        this.goToLoginPage();
    }

    goToLoginPage() {
        // We should save queryParams in url
        const router = this.injector.get(Router);
        const url = this.location.path(false);
        if (url.startsWith('/login')) {  // already there
            return;
        }
        router.navigate(['/', 'login'], {queryParams: {targetUrl: url}});
    }

    addTokenToRequest(req: HttpRequest<any>) {
        if (req.body) {
            // immutability!
            const copyOfBody = JSON.parse(JSON.stringify(req.body));
            copyOfBody.params[TOKEN_FIELD_NAME] = this.getToken();
            req = req.clone({
                body: copyOfBody,
            });
        }
        return req;
    }

    @postOnly
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const panel = this.injector.get(Panel);
        if (
            panel.isNoPanel
            // Imunify Email doesn't support GenericPanel auth yet, but we use it in dev & tests
            && req?.body?.command !== 'commandIE'
        ) {
            if (this.authState.role.value !== I360Role.none) {
                req = this.addTokenToRequest(req);
                return next.handle(req).pipe(
                    switchMap(event => {
                        if (event instanceof HttpResponse) {
                            const response: AgentResponse = event.body;
                            if (response.messages === INVALID_TOKEN_MESSAGE) {
                                this.logout();
                                return this.handleLogout();
                            }
                        }
                        return of(event);
                    }),
                );
            }
            if (req.body?.method?.[0] !== 'login') {
                this.goToLoginPage();
                return this.handleLogout();
            }
        }

        return next.handle(req);
    }

    handleLogout() {
        return EMPTY;
    }

    getToken() {
        return localStorage.getItem(TOKEN_LOCAL_STORAGE_FIELD_NAME) || '';
    }

    setToken(newToken: string) {
        localStorage.setItem(TOKEN_LOCAL_STORAGE_FIELD_NAME, newToken);
        this.parseToken();
    }

    discoverToken() {
        if (typeof i360role !== 'undefined') {
            // for panels
            this.authState.updateRole(i360role, typeof i360userName !== 'undefined' ? i360userName : undefined);
        } else {
            // not for panel extension
            const router = this.injector.get(Router);
            const urlTree = router.parseUrl(this.location.path(false));
            // query params can not be accessed in service via activatedRoute
            const urlToken = urlTree.queryParams.token;
            if (urlToken) {
                localStorage.setItem(TOKEN_LOCAL_STORAGE_FIELD_NAME, urlToken);
                delete urlTree.queryParams.token;
                // can not use 'navigate' because router is not initialized
                // no queryParams - queryParamsHandling: merge will not work
                // no url at all - navigate([]) will not navigate to current url but will to root
                router.navigateByUrl(urlTree || '/', {
                    replaceUrl: true,
                });
            }
            this.parseToken();
        }
    }

    parseToken() {
        let payload: TokenData;
        try {
            payload = jwtDecode<TokenData>(this.getToken());
        } catch (e) {
            payload = {user_type: I360Role.none, username: ''};
        }
        this.authState.updateRole(payload.user_type, payload.username);
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree {
        const urlTree = this.injector.get(Router).parseUrl(state.url);
        return this.check(urlTree);
    }

    canLoad(route: Route, segments: UrlSegment[]): boolean | UrlTree {
        // Neither `route`, nor `segments` doesn't always contain the full path.
        // See https://github.com/angular/angular/issues/17359 for details.
        const currentNavigation = this.injector.get(Router).getCurrentNavigation();
        if (currentNavigation != null) {
            return this.check(currentNavigation.extractedUrl);
        }
        return true;  // idk how this could be possible
    }

    check(urlTree: UrlTree) {
        // https://angular.io/api/router/UrlSegment#example
        const segments = urlTree.root.children?.[PRIMARY_OUTLET]?.segments;
        if (this.authState.role.value === I360Role.none) {
            // if not authorized, only `/login` is available
            if (segments?.[0].path === 'login') {
                return true;
            }
            const targetUrl = urlTree.toString();
            return this.injector.get(Router).createUrlTree(['/', 'login'], {
                queryParams: targetUrl === '/' ? {} : {targetUrl}},
            );
        }
        if (segments?.[0].path !== IMUNIFY_PACKAGE
            || segments?.[1].path !== this.authState.role.value) {
            return this.injector.get(Router)
                .createUrlTree(['/', IMUNIFY_PACKAGE, this.authState.role.value]);
        }
        return true;
    }
}
